import React, { Component } from 'react';
import logo from './et-logo.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './App.css';
import { faTools, bars, faBars, faSpinner } from '@fortawesome/free-solid-svg-icons'

class App extends Component{
  state = {
    collapsed: true
  }

  showMenu() {
    this.setState({collapsed: false})
  }

  hideMenu() {
    this.setState({collapsed: true})
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="app-logo-container">
            <img src={logo} className="App-logo" alt="logo" height="50px" />
          </div>
          <div className="logo-title">
            <p className="title-item">Here to make your tech go right</p>
            <FontAwesomeIcon onClick={this.showMenu.bind(this)}  color="#67696d" focusable icon={faBars} border />
            {this.state.collapsed || <div className="hamburger-menu" onClick={this.hideMenu.bind(this)}><p>Hello</p></div>}
          </div>
        </header>
        <div className="content">
          <div className="construction-notice">
            <FontAwesomeIcon icon={faTools} size="6x" color="green" />
            <p>Site Under Construction</p>
          </div>


        </div>
      </div>
    );

  }
}

export default App;
